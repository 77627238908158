
import React from 'react';
import './BubbleButton.scss';


export default class BubbleButton extends React.Component {


  render() {
    return (
        <div className='bubble_button'>
            {this.props.text}
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
  }
}
