import "./index.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PostPage from "./pages/PostPage";
import AllPosts from "./pages/AllPosts";
import PageNotFound from "./pages/PageNotFound";
import SubscribeThank from "./pages/SubscribeThank";

const PostPageWrapper = () => {
  const { slug } = useParams();
  return <PostPage slug={slug} />;
};

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="posts/:slug" element={<PostPageWrapper />} />
          <Route path="posts/" element={<AllPosts/>} />
          <Route path="newsletter" element={<SubscribeThank/>} />
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
      </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
