import "./SmallPostSummary.scss";
import { Link } from "react-router-dom";
import React from 'react';
import * as urls from "../../urls"
import * as utils from "../../utils"


export default class SmallPostSummary extends React.Component {

  constructor(props){
    super(props);
    this.state = {image_url: {}, categories: utils.request_all_categories(),authors: utils.request_all_authors()}


    this.get_media();
  }

  get_media(){
    if(this.props.media === 0){
      return;
    }
    console.log(`${urls.MEDIA_URL}/${this.props.media}`);
    fetch(`${urls.MEDIA_URL}/${this.props.media}`)
        .then((rsp) => rsp.json())
        .then((data) => {
        this.setState({ image_url: data });
        });
    
  }

  render() {
    console.log(this.state.image_url);
    return (
      <Link to={`/posts/${this.props.slug}`} className="small_post_link">
      <div className="small_post_summary">
        <div className="small_post_heading">
          <img className="small_post_summary_image" src={this.state.image_url.source_url} alt=""/>
          <div className="small_post_heading_text">
            <span className="small_post_summary_title">
              {this.props.title}
            </span>
            <span className="small_post_summary_details">
              {this.props.publishDate} | {this.state.authors[this.props.author]}
            </span>
          </div>
        </div>
      </div>
      </Link>
    );
  }
}
