import * as urls from "./urls"

var CATEGORIES = undefined;
var AUTHORS = undefined;

export function request_all_categories() {
    if (CATEGORIES !== undefined){
        return CATEGORIES
    }

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", urls.GET_ALL_CATEGORIES, false );
    xmlHttp.send( null );
    let data = JSON.parse(xmlHttp.responseText);
    CATEGORIES = {}
    data.forEach(element => {
        CATEGORIES[element.id] = element.name;
    });

    return CATEGORIES;
}

export function request_all_authors() {
    if (AUTHORS !== undefined){
        return AUTHORS
    }

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", urls.GET_USER, false );
    xmlHttp.send( null );
    let data = JSON.parse(xmlHttp.responseText);
    AUTHORS = {}
    data.forEach(element => {
        AUTHORS[element.id] = element.name;
    });

    return AUTHORS;
}
