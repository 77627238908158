import './Cover.scss';
import cover_logo from "../../images/cover_logo.png"
import BubbleButton from '../buttons/BubbleButton';
import { Link } from 'react-router-dom';

function Cover() {
  return (
    <div className="cover">
        <span id="cover_padding"></span>
        <img id="cover_image" src={cover_logo} alt="cover_image"/>
        <Link to="https://chat.whatsapp.com/FVbXQdikHKoDDtdZvy6QZZ" style={{ textDecoration: "none" }} id="join_link">
          <BubbleButton text="הצטרפות לקבוצה" />
        </Link>
    </div>
  );
}

export default Cover;
