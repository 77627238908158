import React from "react";
import "./CategoryTag.scss";


export default class CategoryTag extends React.Component {

    render(){
        return (<div className={`category_tag ${this.props.tag}`}><p className="category_tag_title">{this.props.title}</p></div>)
    }

}