import "./PostPage.scss";
import React from "react";
import * as urls from "../urls";
import "../components/post_summary/PostSummary.scss";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Loader from "../components/widgets/Loader";
import * as utils from "../utils"
import CategoryTag from "../components/tag/CategoryTag";
import ReactHtmlParser from 'react-html-parser'
import Helmet from "react-helmet";

export default class PostPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { post: undefined, image_url: {}, categories: utils.request_all_categories(),authors: utils.request_all_authors()};
    this.get_content(this.props.slug);
  }

  get_content(slug) {
    fetch(`${urls.GET_POST}?slug=${slug}`)
      .then((rsp) => rsp.json())
      .then((data) => {
        this.setState({ post: data });
      });
  }

  get_media(media_id) {
    if (media_id === 0) {
      return;
    }
    fetch(`${urls.MEDIA_URL}/${media_id}`)
      .then((rsp) => rsp.json())
      .then((data) => {
        this.setState({ image_url: data });
      });
  }

  render() {
    let title = undefined;
    let content = undefined;
    let date = undefined;
    let showContent = false;
    let author = undefined;
    let categories = undefined
    let yoastHead = undefined;
    if (this.state.post && this.state.post.length > 0) {
      showContent = true;
      title = this.state.post[0].title.rendered;
      content = this.state.post[0].content.rendered;
      author = this.state.authors[this.state.post[0].author]
      categories = this.state.post[0].categories;
      yoastHead = this.state.post[0].yoast_head;
      console.log(yoastHead)
      date = this.state.post[0].date.split("T")[0];
      if (!this.state.image_url.source_url) {
        this.get_media(this.state.post[0].featured_media);
      }
    } else if (this.state.post && this.state.post.length === 0) {
      window.location.replace("/404");
    }

    return (
      <div id="wrapper">
        <Helmet>
        { ReactHtmlParser(yoastHead) }
        </Helmet>
        <Navbar />
        <div className="post_container">

        {showContent ? (
            <div className="post_summary">
              <div className="post_heading">
                <img
                  className="post_summary_image1"
                  src={this.state.image_url.source_url}
                  alt=""
                />
                <div className="post_heading_text">
                  <span className="post_summary_title1">{title}</span>
                  <span className="post_summary_details1">{date} | {author} </span>
                </div>
              </div>

              <span className="category_tag_list" style={{marginTop: "10px"}}>
                {categories.map(element => {
                  if(element === 1) return <></>;
                  return <CategoryTag key={element} title={this.state.categories[element]} tag={`tag_${element}`}/>
                })}
              </span>
              <div
                className="post_summary_text1"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </div>
        ) : (
          <div className="posts_loader1">
            <div className="load_icon1">
              <Loader />
              <span>העמוד בטעינה</span>
            </div>
          </div>
        )}
        </div>

        <br />
        <Footer />
      </div>
    );
  }
}
