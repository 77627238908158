import './Loader.scss';

function Loader(){
    return(
        <div className='loaderWrapper'>
            <div class="loader"></div>
        </div>
    
    );
}

export default Loader;