import "./Navbar.scss";
import cover_logo from "../../images/cover_logo.png";
import menu from "../../images/menu.png";
import { Link } from "react-router-dom";
import React from "react";
import BubbleButton from "../buttons/BubbleButton";

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false, animate: false, stick: false };

    setTimeout(() => this.setState({ animate: true }), 500);

    this.navbar = React.createRef();
    this.place_holder = React.createRef();

    window.addEventListener("scroll", (event) => {
      let top = window.pageYOffset || document.documentElement.scrollTop;

      if (top >= 500 && !this.state.stick) {
        this.setState({ stick: true });
      } else if (top === 0 && this.state.stick) {
        this.setState({ stick: false });
      }
    });
  }

  componentDidMount() {
    const resize = () => {
      this.place_holder.current.style.width = `10px`;
      this.place_holder.current.style.height = `${
        this.navbar.current.clientHeight
      }px`;
      this.place_holder.current.style.position = "relative";
    };
    resize();
  }

  render() {
    console.log("render");
    return (
      <>
        <div
          ref={this.navbar}
          className={`navbar ${this.state.stick ? "stick" : ""}`}
        >
          <div id="desktop">
            <Link to="/" id="homepage_link">
              <img src={cover_logo} alt="pasten" id="finish_line" />
            </Link>
            <span className="navContent">
              <Link to="/posts/" style={{ textDecoration: "none" }}>
                <BubbleButton text="פוסטים" />
              </Link>
              {/* <BubbleButton text="אני רוצה להתחיל לרוץ"/>
              <BubbleButton text="צור קשר"/> */}
            </span>
            <span className="space_holder" />
          </div>

          <div id="mobile">
            <div id="menu_icon_wrapper">
              <Link to="/" id="homepage_link">
                <img src={cover_logo} alt="pasten" id="finish_line" />
              </Link>
              <img
                src={menu}
                alt=""
                id="menu_img"
                onClick={() =>
                  this.setState({ showMenu: !this.state.showMenu })
                }
              />
            </div>
            <span
              className={`navContent ${this.state.showMenu ? "show" : "hide"} ${
                this.state.animate ? "" : "freeze-anim"
              }`}
            >
              <Link to="/posts/" style={{ textDecoration: "none" }} className="navbutton">
                פוסטים
              </Link>
              {/* <span className="navbutton">אני רוצה להתחיל לרוץ!</span>
              <span className="navbutton">צור קשר</span> */}
            </span>
          </div>
        </div>
        <div ref={this.place_holder}></div>
      </>
    );
  }
}
