import React from "react";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import SmallPostSummary from "../components/post_summary/SmallPostSummary";
import Loader from "../components/widgets/Loader";
import * as urls from "../urls";

import "./AllPosts.scss";

export default class AllPosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { posts: undefined };
    this.request_posts();
    document.title = "פוסטים";
  }

  request_posts() {
    fetch(`${urls.GET_RECENT_POSTS_URL}&per_page=100`)
        .then((rsp) => rsp.json())
        .then((data) => {
        this.setState({ posts: data });
        });
  }

  render() {
    console.log(this.state.posts)
    return (
      <>
        <Navbar />
        <div className="posts_page_content">
            <span id="all_posts_title">כל הפוסטים</span>
          {this.state.posts ? (
            <div id="all_posts">
              {this.state.posts.map((post) => (
                <SmallPostSummary
                  title={post.title.rendered}
                  readingTime="חמש דקות קריאה"
                  publishDate={post.date.split("T")[0]}
                  contentSummary={post.excerpt.rendered}
                  slug={post.slug}
                  media={post.featured_media}
                  author={post.author}
                />
              ))}
            </div>
          ) : (
            <div className="posts_loader2">
              <div className="load_icon2">
                <Loader />
                <span>העמוד בטעינה</span>
              </div>
            </div>
          )}
        </div>

        <Footer />
      </>
    );
  }
}
