import React from "react";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import "./PageNotFound.scss";
import notfound from '../images/notfound.png'


export default class PageNotFound extends React.Component{

    constructor(props){
        super(props);
        document.title = "404 - דף לא נמצא";
    }

    render(){
        return(
            <>
                <Navbar/>
                <div id="not_found_page">
                    <img id="_404_image" src={notfound} alt=""/>
                    <span id="_404_title"> 404 </span>
                    <span id="_404_subtitle"> הדף הנוכחי יצא לריצה </span>
                </div>
                <Footer/>
            </>
        );
    }
}