import React from 'react';
import "./Footer.scss";
import BubbleButton from '../buttons/BubbleButton';
import * as urls from '../../urls';


export default class Footer extends React.Component {

    constructor(props){
        super(props);
        this.newsletter_form = React.createRef();
    }

    render(){
        return(
            <div id="footer">
                <div id="footer_text">
                    <span id="footer_title">הישארו מעודכנים!</span>
                    <span id="footer_details">היו הראשונים לקבל את הפוסטים שלי ישירות לדוא"ל!</span>
                    <form id="mail_form" method="post" action={urls.NEWSLETTER_SUBSCRIBE_URL}>
                    
                        <input id="mail_input" placeholder='כתובת דוא"ל' className="tnp-email" type="email" name="ne" required/>
                        <input ref={this.newsletter_form} type="submit" hidden/>
                        <span id="submit_button" onClick={() => this.newsletter_form.current.click()}>
                            <BubbleButton text="הרשמה"/>
                        </span>
                    </form>
                </div>
                
                <br/>
            </div>
        );
    }
}