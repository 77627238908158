import "./HomePage.scss";
import React from "react";
import * as urls from "../urls";
import Cover from "../components/cover/Cover.js";
import Navbar from "../components/navbar/Navbar";
import PostSummary from "../components/post_summary/PostSummary";
import Footer from "../components/footer/Footer";
import Loader from "../components/widgets/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../components/buttons/ActionButton";

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { posts: undefined };
    this.request_posts();
    document.title = "פסטנים בריצה";
  }

  request_posts() {
    fetch(`${urls.GET_RECENT_POSTS_URL}&per_page=3`)
      .then((rsp) => rsp.json())
      .then((data) => {
        this.setState({ posts: data });
      });
  }

  render() {

    return (
      <div id="wrapper">
        <Navbar />
        <Cover id="cover" />
        {this.state.posts ? (
          <div id="latest_posts">
            <p id="latest_posts_title">פוסטים אחרונים</p>

            {this.state.posts.map((post) => (
              <PostSummary
                title={post.title.rendered}
                readingTime="חמש דקות קריאה"
                publishDate={post.date.split("T")[0]}
                contentSummary={post.excerpt.rendered}
                slug={post.slug}
                media={post.featured_media}
                categories={post.categories}
                author={post.author}
              />
            ))}

            <Link to={`/posts`} className="post_link1"><ActionButton text="כל&nbsp;הפוסטים"/></Link>
          </div>
        ) : (
          <div className="posts_loader">
            <div className="load_icon">
              <Loader />
              <span>העמוד בטעינה</span>
            </div>
          </div>
        )}

        <Footer />
      </div>
    );
  }
}
