import "./PostSummary.scss";
import { Link } from "react-router-dom";
import React from 'react';
import ActionButton from "../buttons/ActionButton";
import * as urls from "../../urls";
import * as utils from "../../utils"
import CategoryTag from "../tag/CategoryTag";

export default class PostSummary extends React.Component {

  constructor(props){
    super(props);
    this.state = {image_url: {}, categories: utils.request_all_categories(),authors: utils.request_all_authors()}

    console.log(this.state.authors);
    this.get_media();
  }

  get_media(){
    if(this.props.media === 0){
      return;
    }
    fetch(`${urls.MEDIA_URL}/${this.props.media}`)
        .then((rsp) => rsp.json())
        .then((data) => {
        this.setState({ image_url: data });
        });
    
  }


  render() {
    return (
      <div className="post_summary">
        <div className="post_heading">
          <img className="post_summary_image" src={this.state.image_url.source_url}  alt="pasten"/>
          <div className="post_heading_text">
            <span className="post_summary_title">
              {this.props.title}
            </span>
            
            <span className="post_summary_details">
              {this.props.publishDate} | {this.state.authors[this.props.author]}
            </span>
          </div>
        </div>

        <div className="post_summary_text_cont">
         
          <div className="post_summary_text" dangerouslySetInnerHTML={{ __html: this.props.contentSummary}}></div>
          <span className="category_tag_list">
              {this.props.categories.map(element => {
                if(element === 1) return <></>;
                return <CategoryTag key={element} title={this.state.categories[element]} tag={`tag_${element}`}/>
              })}
          </span>
        </div>
        <Link to={`/posts/${this.props.slug}`} className="post_link"><ActionButton text="לקריאה"/></Link>
      </div>
    );
  }
}
