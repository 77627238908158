import React from "react";
import "./SubscribeThank.scss";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import newsletter from "../images/newsletter.png";
import BubbleButton from "../components/buttons/BubbleButton";
import { Link } from "react-router-dom";

export default class SubscribeThank extends React.Component {

    constructor(props){
        super(props);
        document.title = "תודה";
    }


    render() {
        return(
            <>
            <Navbar/>
            <div id="newsletter_page">
                <img id="newsletter_image" src={newsletter} alt=""/>
                <span id="newsletter_title"> ההרשמה בוצעה! </span>
                <span id="newsletter_subtitle"> בקרוב תקבלו עדכונים חדשים :) </span>
                <Link id="return_button" to="/">
                    <BubbleButton text="חזרה לדף הבית"/>
                </Link>
            </div>
            <Footer/>
        </>
        );
    }
}